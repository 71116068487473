<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content graphShadow">
      <el-tree
        class="treeWraper"
        accordion
        ref="tree"
        @node-click="nodeClick"
        :load="loadNode1"
        :expand-on-click-node="false"
        lazy
        :props="defaultProps"
        :render-content="renderContent"
        v-if="isUpdate"
      ></el-tree>
      <el-dialog :title="titleHeader" :visible.sync="dialogTableVisible" width="30%">
        <el-form :model="formInline" ref="ruleForm" :rules="rules" label-width="120px">
          <el-form-item label="区域类型" prop="chargeable">
            <el-radio
              v-model="formInline.areaType"
              :label="0"
              :disabled="dialogIsEdit"
              v-if="isXNQadd"
              >常规</el-radio
            >
            <el-radio
              v-model="formInline.areaType"
              :label="1"
              :disabled="dialogIsEdit || sendFalseXN"
              >虚拟区</el-radio
            >
          </el-form-item>
          <el-form-item :label="formInline.areaType == 0 ? '区域名称' : '虚拟区域名称'" prop="name">
            <el-input
              v-model="formInline.name"
              style="width: 90%"
              maxlength="10"
              v-if="formInline.areaType != 0"
            ></el-input>
            <el-autocomplete
              v-else
              style="width: 90%"
              class="inline-input"
              v-model="formInline.name"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入区域名称"
              value-key="areaName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item :label="formInline.areaType == 0 ? '区域编码' : '虚拟区域编码'" prop="code">
            <el-input
              v-model="formInline.code"
              maxlength="20"
              style="width: 90%"
              :disabled="formInline.areaType == 0"
            ></el-input>
          </el-form-item>
          <el-form-item label="真实区域编码" v-if="formInline.areaType == 1">
            <el-input
              v-model="formInline.realAreaCode"
              maxlength="20"
              style="width: 90%"
            ></el-input>
          </el-form-item>
          <el-form-item label="坐标中心点" class="form_lng" prop="longitude">
            <el-input
              :maxlength="20"
              v-model.trim="lnglat"
              placeholder="在地图上选择坐标点"
              style="width: 53%"
              clearable
            ></el-input>
            <el-button type="primary" style="margin-left: 2%; width: 35%" @click="openMap"
              >地图选点
            </el-button>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFun">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <map-con ref="mapConpent" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
  </div>
</template>
<script>
// import jsonData from '@/common/jsonData.json'
import mapCon from "@/components/map";
export default {
  name: "organizationManage",
  components: {
    mapCon,
  },
  data() {
    return {
      isUpdate: true,
      sendFalseXN: false, // 一级添加禁掉虚拟区
      isXNQadd: true,
      modelvalue: "",
      lnglat: "",
      titleHeader: "",
      loadArr: [],
      labelNameValue: "",
      labelCodeValue: "",
      dialogIsEdit: false,
      formInline: {
        name: "",
        code: "",
        areaType: 0,
        realAreaCode: "",
        latitude: "",
        longitude: "",
      },
      dialogTableVisible: false,
      areaList: [],
      childrenArea: [],
      resourceList: [],
      defaultProps: {
        children: "childrenAreas",
        label: "areaName",
        isLeaf: "leaf",
      },
      ruleForm: {
        areaName: "",
        order: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入区域名称",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length > 20) {
                callback(new Error("不大于20位的组合"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入区域编码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!/^[0-9A-Za-z]{1,20}$/.test(value)) {
                callback(new Error("不大于20位的英文字母大小写或数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    lnglat() {
      if (!this.lnglat) {
        this.formInline.latitude = "";
        this.formInline.longitude = "";
      }
    },
  },
  methods: {
    querySearchAsync(queryString, cb) {
      console.log(queryString, "queryString");
      queryString = queryString.replace(/\s/g, "");
      let parentAreaCode = this.activeData.areaCode;
      let data = {};
      let url =
        this.titleHeader == "编辑区域"
          ? "/acb/2.0/areaDictionary/queryAreaDictionary4Update"
          : "/acb/2.0/areaDictionary/queryAreaDictionary";
      if (this.titleHeader == "编辑区域") {
        data.areaName = queryString;
        data.areaCode = parentAreaCode;
      } else {
        data.areaName = queryString;
        data.parentAreaCode = parentAreaCode;
      }
      this.$axios
        .get(url, {
          data: data,
        })
        .then((res) => {
          console.log(res.value);
          if (res.value && res.value.length > 0) {
            this.lnglat = res.value[0].longitude / 1000000 + "," + res.value[0].latitude / 1000000;
            cb(res.value);
          } else {
            cb([]);
          }
        });
    },
    handleSelect(item) {
      this.formInline.code = item.areaCode;
      console.log(item, "jjj");
      // this.$emit('valueChange', item);
    },
    openMap() {
      // let city = this.treeData[0].areaName;
      this.$refs.mapConpent.openMap(this.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      this.formInline.parkAddress = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    nodeClick(data, node, store) {
      console.log(data, node, "kkkkkg");
      this.activeData = data;
      this.activeNode = node;
      this.depth = data.depth;
    },
    dialogFun() {
      if (this.dialogIsEdit) {
        this.editArea(this.activeData);
      } else {
        this.addArea(this.activeData);
      }
    },
    formatTree(targetList) {
      let areaList = targetList.slice(0);
      let list = [];
      for (let i in areaList) {
        for (let j in areaList) {
          if (areaList[j].parentId == areaList[i].resourceId) {
            //   areaList[i].children ? areaList[i].children.push(areaList[j]) : areaList[i].children = [areaList[j]];
          }
        }
        if (!areaList[i].parentId) {
          list.push(areaList[i]);
        }
      }
      return list;
    },
    renderContent(createElement, { node, data, store }) {
      let self = this;
      if (data.depth < 3) {
        // 顶级
        return createElement(
          "div",
          {
            attrs: {
              class: "treeButtonRelative",
            },
          },
          [
            createElement("div", node.label),
            createElement(
              "span",
              {
                attrs: {
                  class: "treeButton",
                  style:
                    "display:" + (self.$route.meta.authority.button.add ? "inline-block" : "none"),
                },
              },
              [
                createElement("i", {
                  attrs: {
                    class: "el-icon-circle-plus",
                  },
                  on: {
                    click: function () {
                      setTimeout(() => {
                        self.formInline.name = "";
                        self.formInline.code = "";
                        self.formInline.realAreaCode = "";
                        self.formInline.latitude = "";
                        self.formInline.longitude = "";
                        self.lnglat = "";
                        self.labelNameValue = "二级节点名称";
                        self.labelCodeValue = "二级节点编码";
                        self.titleHeader = "添加区域";
                        self.sendFalseXN = true;
                        if (data.areaType == 1) {
                          self.formInline.areaType = data.areaType;
                          self.isXNQadd = false;
                          self.titleHeader = "添加虚拟区域";
                        } else {
                          self.isXNQadd = true;
                          self.formInline.areaType = 0;
                        }
                        self.dialogIsEdit = false;
                        self.dialogTableVisible = true;
                      }, 0);
                      setTimeout(() => {
                        self.$refs["ruleForm"].resetFields();
                      }, 100);
                    },
                  },
                }),
              ]
            ),
          ]
        );
      } else if (data.depth == 3) {
        return createElement(
          "div",
          {
            attrs: {
              class: "treeButtonRelative",
            },
          },
          [
            createElement("div", node.label),
            createElement(
              "span",
              {
                attrs: {
                  class: "treeButton",
                },
              },
              [
                createElement("i", {
                  attrs: {
                    class: "el-icon-circle-plus",
                    style:
                      "display:" +
                      (self.$route.meta.authority.button.add ? "inline-block" : "none"),
                  },
                  on: {
                    click: function (event) {
                      setTimeout(() => {
                        self.dialogIsEdit = false;
                        self.dialogTableVisible = true;
                        self.sendFalseXN = false;
                        self.$refs["ruleForm"]?.resetFields();
                        self.formInline.name = "";
                        self.formInline.code = "";
                        self.formInline.realAreaCode = "";
                        self.formInline.latitude = "";
                        self.formInline.longitude = "";
                        self.lnglat = "";
                        self.labelNameValue = "三级节点名称";
                        self.labelCodeValue = "三级节点编码";
                        self.titleHeader = "添加区域";
                        if (data.areaType == 1) {
                          self.formInline.areaType = data.areaType;
                          self.isXNQadd = false;
                          self.titleHeader = "添加虚拟区域";
                        } else {
                          self.isXNQadd = true;
                          self.formInline.areaType = 0;
                        }
                      }, 0);
                    },
                  },
                }),
                createElement("i", {
                  attrs: {
                    class: "el-icon-edit",
                    style:
                      "display:" +
                      (self.$route.meta.authority.button.update ? "inline-block" : "none"),
                  },
                  on: {
                    click: function () {
                      setTimeout(() => {
                        self.dialogIsEdit = true;
                        self.dialogTableVisible = true;
                        self.sendFalseXN = false;
                        self.$refs["ruleForm"]?.resetFields();
                        self.formInline.name = data.areaName;
                        self.formInline.code = data.areaCode;
                        self.formInline.areaType = data.areaType;
                        if (data.latitude && data.longitude) {
                          self.formInline.latitude = data.latitude;
                          self.formInline.longitude = data.longitude;
                          self.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
                        } else {
                          self.formInline.latitude = "";
                          self.formInline.longitude = "";
                          self.lnglat = "";
                        }
                        self.labelNameValue = "二级节点名称";
                        self.labelCodeValue = "二级节点编码";
                        self.titleHeader = "编辑区域";
                        if (data.areaType == 1) {
                          self.titleHeader = "编辑虚拟区域";
                          self.formInline.realAreaCode = data.realAreaCode;
                        }
                      }, 0);
                    },
                  },
                }),
                createElement("i", {
                  attrs: {
                    class: "el-icon-remove",
                    style:
                      "display:" +
                      (self.$route.meta.authority.button.delete ? "inline-block" : "none"),
                  },
                  on: {
                    click: function () {
                      self.removeArea(data);
                    },
                  },
                }),
              ]
            ),
          ]
        );
      } else {
        return createElement(
          "div",
          {
            attrs: {
              class: "treeButtonRelative",
            },
          },
          [
            createElement("div", node.label),
            createElement(
              "span",
              {
                attrs: {
                  class: "treeButton",
                },
              },
              [
                createElement("i", {
                  attrs: {
                    class: "el-icon-edit",
                    style:
                      "display:" +
                      (self.$route.meta.authority.button.update ? "inline-block" : "none"),
                  },
                  on: {
                    click: function () {
                      setTimeout(() => {
                        self.dialogIsEdit = true;
                        self.dialogTableVisible = true;
                        self.sendFalseXN = false;
                        self.$refs["ruleForm"]?.resetFields();
                        self.formInline.name = data.areaName;
                        self.formInline.code = data.areaCode;
                        self.formInline.areaType = data.areaType;
                        if (data.latitude && data.longitude) {
                          self.formInline.latitude = data.latitude;
                          self.formInline.longitude = data.longitude;
                          self.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
                        } else {
                          self.formInline.latitude = "";
                          self.formInline.longitude = "";
                          self.lnglat = "";
                        }
                        self.labelNameValue = "三级节点名称";
                        self.labelCodeValue = "三级节点编码";
                        self.titleHeader = "编辑区域";
                        if (data.areaType == 1) {
                          self.titleHeader = "编辑虚拟区域";
                          self.formInline.realAreaCode = data.realAreaCode;
                        }
                      }, 0);
                    },
                  },
                }),
                createElement("i", {
                  attrs: {
                    class: "el-icon-remove",
                    style:
                      "display:" +
                      (self.$route.meta.authority.button.delete ? "inline-block" : "none"),
                  },
                  on: {
                    click: function () {
                      self.removeArea(data);
                    },
                  },
                }),
              ]
            ),
          ]
        );
      }
    },

    // 懒加载显示子级
    loadNode1(node, resolve) {
      this.loadArr.push({ node: node, resolve: resolve });
      this.node = node;
      this.resolve = resolve;
      if (node.level === 0) {
        setTimeout(() => {
          resolve(this.areaList);
        }, 500);
      } else if (node.level === 1) {
        setTimeout(() => {
          resolve(this.areaList[0].childrenAreas);
          this.$set(node.data, "children", this.areaList[0].childrenAreas);
        }, 500);
      } else if (node.level === 2) {
        setTimeout(() => {
          this.getChildrenArea(node.data.areaId, resolve, node);
        }, 500);
      }
    },
    // 获取下级区域列表
    getChildrenArea(areaId, resolve, node) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: areaId,
          },
        })
        .then((res) => {
          for (let i = 0; i < res.value.length; i++) {
            this.$set(res.value[i], "leaf", true);
          }
          resolve(res.value);
          this.$set(node.data, "children", res.value);
        });
    },
    // 删除地区
    removeArea(data) {
      this.$confirm("您确认要删除该节点吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.post("/acb/2.0/area/" + data.areaId).then((res) => {
            if (res.state == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.deleteLocalDep(this.activeNode.parent, data);
              // this.$router.go(0)
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteLocalDep(treeData, data) {
      for (var i = 0; i < treeData.childNodes.length; i++) {
        var td = treeData.childNodes[i];
        if (td.data.areaCode == data.areaCode) {
          treeData.childNodes.splice(i, 1);
          treeData.data.children.splice(i, 1);
          return;
        }
      }
    },
    // 编辑地区
    editArea(data) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let name = this.formInline.name;
          let code = this.formInline.code;
          this.$axios
            .post("/acb/2.0/modifyArea ", {
              data: {
                areaId: data.areaId,
                areaName: name,
                areaCode: code,
                order: 0,
                areaType: this.formInline.areaType,
                realAreaCode: this.formInline.areaType == 1 ? this.formInline.realAreaCode : "",
                latitude: this.formInline.latitude,
                longitude: this.formInline.longitude,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogIsEdit = false;
                this.dialogTableVisible = false;
                this.formInline.name = "";
                this.$set(data, "areaName", name);
                this.$set(data, "areaCode", code);
                // this.$router.go(0)
              } else {
                this.$message({
                  type: "info",
                  message: res.desc,
                });
              }
              this.restTree();
            });
        } else {
          return false;
        }
      });
    },
    append(data, code, name, areaId) {
      const newChild = {
        areaCode: code,
        areaName: name,
        areaId: areaId,
        depth: data.depth * 1 + 1,
        children: [],
      };
      if (this.activeData.depth == 2) {
        if (!data.children) {
          this.activeData.children = this.activeData.childrenAreas;
        }
        this.activeData.children.push(newChild);
      } else if (!data.children && this.activeData.depth > 2) {
      } else {
        this.$set(newChild, "leaf", true);
        this.activeData.children.push(newChild);
        for (let i = 0; i < this.loadArr.length; i++) {
          if (this.loadArr[i].node.data) {
            if (this.loadArr[i].node.data.areaId == this.activeData.areaId) {
              this.loadArr[i].resolve(this.activeData.children);
            }
          }
        }
      }
    },
    // 添加地区
    addArea(data) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let name = this.formInline.name;
          let code = this.formInline.code;
          this.$axios
            .post("/acb/2.0/area", {
              data: {
                parentId: data.areaId,
                areaName: name,
                areaCode: code,
                order: 0,
                areaType: this.formInline.areaType,
                realAreaCode: this.formInline.areaType == 1 ? this.formInline.realAreaCode : "",
                latitude: this.formInline.latitude,
                longitude: this.formInline.longitude,
              },
            })
            .then((res) => {
              this.formInline.name = "";
              this.formInline.code = "";
              if (res.state == 0) {
                this.dialogIsEdit = false;
                this.dialogTableVisible = false;
                let areaId = res.value;
                this.append(data, code, name, areaId);
                // this.$router.go(0)
              } else {
                this.$message({
                  type: "info",
                  message: res.desc,
                });
              }
              this.restTree();
            });
        } else {
          return false;
        }
      });
    },

    // 获取当前用户的区域列表
    getArealist() {
      this.$axios.get("/acb/2.0/area/getAllArea").then((res) => {
        this.areaList = res.value;
      });
    },
    restTree() {
      this.isUpdate = false;
      this.getArealist();
      setTimeout(() => {
        this.isUpdate = true;
      }, 150);
    },
  },

  created() {},
  mounted() {
    this.getArealist();
  },
};
</script>
<style media="screen">
.treeButton {
  position: absolute;
  right: -100px;
  top: 0;
  color: #666;
}

.treeButton i {
  margin-right: 10px;
}

.treeButtonRelative {
  position: relative;
}
/* .treeWraper .el-form-item__content {
  float: left;
  width: 240px;
} */
/* .treeWraper .el-form-item__content input {
  width: 100%;
} */
/* .msgboxCon label {
  text-align: right;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 10px;
} */

/* .msgboxCon label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.msgboxCon input {
  margin-bottom: 10px;
  position: relative;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 60%;
} */
.treeWraper .el-tree-node__content {
  height: 40px;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
}
</style>
